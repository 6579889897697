import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotes, postNotes } from "../../services/api";
import * as PR from "../../prime-modules/index";
import moment from "moment";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Notes = (orderId) => {
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const [notes, setNotes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const orderIds = orderId.orderId;

  const displayNotes = useCallback(() => {
    getNotes(dispatch, headers, orderIds, (response) => {
      if (response.result === "SUCCESS") {
        const sortedNotes = response.data.sort(
          (a, b) => new Date(b.createdTs) - new Date(a.createdTs)
        );
        setNotes(sortedNotes);
      }
    });
  }, [dispatch, headers, orderIds]);

  useEffect(() => {
    displayNotes();
  }, [displayNotes]);

  const handleSave = (values, { resetForm }) => {
    const { title, description } = values;
    if (description.trim() || title.trim()) {
      const obj = { title, description };
      const saveNotesResponse = (response) => {
        if (response.result === "SUCCESS") {
          const newNote = {
            title,
            description,
            createdTs: new Date(),
          };
          setNotes((prevNotes) => [newNote, ...prevNotes]);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Saved successfully",
          });
          resetForm();
          setIsEditing(false);
        } else if (response.result === "FAILED" && response.error) {
          const error = response.error;
          toast.current?.show({
            severity: error.severity,
            summary: "Error",
            detail: error.errorMsg ? error.errorMsg : error.summary,
          });
        }
      };
      postNotes(obj, dispatch, headers, orderIds, saveNotesResponse);
    }
  };

  const handleCancel = (resetForm) => {
    resetForm();
    setIsEditing(false);
  };

  const headerTemplate = () => (
    <div className="notes-header">
      <span>Notes</span>
      <PR.Button
        icon="pi pi-plus"
        className="p-button-sm p-button-secondary ml-10"
        onClick={() => setIsEditing(true)}
      />
    </div>
  );

  const timeFormat = (createdTs) => {
    const momentTs = moment(createdTs);
    return momentTs.format("ddd, MMM DD, YYYY | hh:mm a");
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, "Title must be at least 3 characters")
      .max(100, "Title must be at most 100 characters"),
    description: Yup.string()
      .min(3, "Description must be at least 3 characters")
      .max(1000, "Description must be at most 1000 characters"),
  });

  return (
    <PR.Panel header={headerTemplate}>
      <div className="p-3">
        {isEditing && (
          <Formik
            initialValues={{ title: "", description: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({ resetForm }) => (
              <Form className="p-mb-2 add-notes">
                <div>
                  <Field
                    name="title"
                    as={PR.InputText}
                    placeholder="Add your note title"
                    className="p-mr-2 p-mb-2"
                    style={{ width: "100%", minHeight: "50px", resize: "none" }}
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="p-error"
                  />
                </div>
                <div>
                  <Field
                    name="description"
                    as={PR.InputTextarea}
                    placeholder="Write your note"
                    className="p-mr-2"
                    autoResize
                    rows={4}
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      maxHeight: "300px",
                    }}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="p-error"
                  />
                </div>
                <div className="p-mt-2 text-right">
                  <PR.Button
                    type="submit"
                    label="Save"
                    className="p-button-secondary save"
                  />
                  <PR.Button
                    label="Cancel"
                    onClick={() => handleCancel(resetForm)}
                    className="p-button-danger"
                  />
                </div>
              </Form>
            )}
          </Formik>
        )}

        <div className="notes-block">
          {notes.map((note, index) => (
            <div key={index} className="notes-list">
              <>
                <div className="title">
                  <span
                    className="p-mr-2"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      width: "100%",
                    }}
                  >
                    <strong>{note.title}</strong>
                  </span>
                </div>
                <div className="description">
                  <span
                    className="p-mr-2"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      width: "100%",
                    }}
                  >
                    {note.description}
                  </span>
                </div>
                <div className="time">
                  <span className="mr-12">{timeFormat(note.createdTs)}</span>
                </div>
              </>
            </div>
          ))}
        </div>
        <PR.Toast ref={toast} position="top-right" />
      </div>
    </PR.Panel>
  );
};

export default Notes;
